.app__works {
    padding: var(--app-padding);
    padding-top: 5rem;

    @media screen and (max-width: 900px) {
        padding: var(--app-padding-sm);
        padding-top: 5rem;
    }
}

.app__works-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0;
    h1 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 0.03em;
    }
}

.app__works-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 3rem;
    column-gap: 2rem;

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 100%;
    }
}

.app__works-gallery_item {
    border-bottom: 1px solid var(--text-color-secondary);
    img {
        width: 100%;
    }
    
    p {
        padding: 0.5rem 0;
        color: var(--text-color-secondary);
        font-weight: 400;
        font-size: 20px;
        line-height: 122.4%;
        letter-spacing: 0.03em;
    }
}

@media screen and (max-width: 900px) {
    .app__works {
        padding: var(--app-padding-sm);
        padding-top: 5rem;
    }
}