.app__about {
    padding: var(--app-padding);
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 900px) {
        padding: var(--app-padding-sm);
    }
}

.app__about-content {
    background: #fff;
    padding: 3rem 5rem;
    box-shadow: 0px 8px 30px rgba(38, 38, 38, 0.15);
    margin-top: -3rem;

    h1 {
        color: var(--text-color-primary);
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 3%;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 900px) {
        margin-top: 1rem;
    }

    @media screen and (max-width: 320px) {
        padding: 2rem 3rem;
    }
}

.app__about-content_description {
    display: flex;
    flex-direction: row;

    img {
        width: 270px;
    }

    p {
        font-size: 20px;
        line-height: 24px;
        text-align: justify;
        letter-spacing: 2%;
        color: #535151;

        padding: 2rem 0 0 2rem;
    }

    @media screen and (max-width: 900px) {
        flex-direction: column;

        img {
            width: 100%;
        }

        p {
            padding: 2rem 0;
        }
    }

    @media screen and (max-width: 320px) {        
        p {
            font-size: 14px;
            line-height: 18px;
            text-align: left;
        }
    }
}

@media screen and (max-width: 900px) {
    .app__about {
        padding: var(--app-padding-sm);
    }
}