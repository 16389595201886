.app {
    font-family: var(--font-base);
    background-color: var(--background-primary)
}

.custom__button {
    background: var(--primary-gradient);
    color: #fff;
    padding: 1rem 2rem;
    border-radius: 15px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
}

.custom__button:hover {
    filter: brightness(80%);
}

.gradient__text {
    background: var(--primary-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}