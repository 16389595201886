@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');


@font-face {
    font-family: "BeautyMountain";
    src: local("BeautyMountain"), url("./fonts/BeautyMountains.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Geraldine-Italic";
    src: local("Geraldine-Italic"), url("./fonts/Geraldine-Italic.ttf") format("truetype");
    font-weight: bold;
}
    
:root {
    --font-base: "open sans", "BeautyMountain";
    --font-alt: "Geraldine-Italic";
    --text-color-primary: #251D1D;
    --text-color-secondary: #535151;
    --primary-red: #913651;
    --primary-gradient: linear-gradient(93.06deg, #CC186E -2.84%, #913651 105.38%);
    --footer-gradient: linear-gradient(93.06deg, #CC186E -2.84%, #8B1717 105.38%);
    --service-background-image: url('./assets/images/background.png');
    --app-padding: 0 5rem;
    --app-padding-sm: 0 2.5rem;
    --background-primary: #f0f0f0;
    --background-secondary: #E5E5E5;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    font-weight: 600;
}

.slide-bottom {
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-bottom {
    0% {
        transform: translateY(-25%);
        -webkit-transform: translateY(-25%);
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
}

@-webkit-keyframes slide-bottom {
    0% {
        transform: translateY(-25%);
        -webkit-transform: translateY(-25%);
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
}