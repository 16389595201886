.app__navbar {
    padding: var(--app-padding);
    background: var(--background-secondary);
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 900px) {
        padding: var(--app-padding-sm);
        padding-top: 3rem;
    }
}

.app__navbar-logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    height: auto;
    z-index: 7;
    img {
        width: 100%;
    }
    p {
        z-index: 7;
        font-family: var(--font-alt);
        color: var(--primary-red);
        font-size: 1.5em;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 500px) {
        width: 75px;
    }

    @media screen and (max-width: 320px) {
        width: 60px;
    }
}

.app__navbar-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 2;

    .app__navbar-links_item {
        padding: 1rem 1.5rem;
        a {
            color: var(--primary-red);
            text-transform: capitalize;
        }
    }

    .app__navbar-links_item:after {
        display: block;
        content: '';
        border-bottom: solid 2px var(--primary-red);  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        }

    .app__navbar-links_item:hover:after {
        border-bottom: 2px solid var(--primary-red);
        transition: transform 250ms ease-in-out;
        transform: scaleX(1);
    }
    
    @media screen and (max-width: 900px) {
        display: none;
        .app__navbar-links_item {
            padding: 1rem 1.5rem;
        }
    }
}


.app__navbar-menu {
    display: none;

    .app__navbar-menu__overlay {
        background: var(--primary-red);
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;

        transition: 0.5s ease;

        z-index: 5;
    }

    .app__navbar-menu_hamburger, .app__navbar-menu_close {
        z-index: 7;
        font-size: 27px;
        color: var(--primary-red);
        cursor: pointer;
    }

    .app__navbar-menu_close {
        position: fixed;
        top: 4.5rem;
        right: 2rem;
        
        font-size: 2.5rem;
        color: #fff;

    }

    .app__navbar-links {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .app__navbar-links_item {
        padding: 2rem 0;

        a {
            font-family: "BeautyMountain";
            letter-spacing: .5rem;
            font-family: var(--font-alt);
            color: #fff;
            text-transform: capitalize;
            font-size: 2.5rem;

        }
        @media screen and (max-width: 480px) {
            padding: 1rem 0;
            a {
                font-size: 2.5rem;
            }
        }
    }

    @media screen and (max-width: 900px) {
        display: flex;
    }

}