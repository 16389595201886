.app__header {
    padding: var(--app-padding);
    background: var(--background-secondary);
    
    
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
    padding-top: 3rem;

    @media screen and (max-width: 900px) {
        padding: var(--app-padding-sm);
    }
}

.app__header-heading {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}

.app__header-heading_text {
    margin: 2rem 0;
    flex: 1.5;
    
    h1 {
        font-style: bold;
        font-size: 64px;
        line-height: 72px;
        vertical-align: top;
        letter-spacing: 2%;
    }
    
    p {
        margin: 2rem 0;
        font-size: 20px;
        line-height: 24px;
    }
    
    @media screen and (max-width: 1100px) {
        h1 {
            font-size: 48px;
            line-height: 54px;
        }

        p {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 900px) {
        .app__header-heading_text {
            display: flex;
            flex-direction: column;
            
            h1 {
                font-size: 30px;
            }
        }
    }

    @media screen and (max-width: 480px) {
        h1 {
            font-size: 36px;
            line-height: 42px;
        }

        p {
            font-size: 18px;
        }
    }

    @media screen and (max-width: 320px) {
        h1 {
            font-size: 32px;
        }
    }
}
        
.app__header-heading_image {
    flex: 1;
    width: 546px;
    
    img {
        width: 100%;
    }

    @media screen and (max-width: 1100px) {
       width: 430px;
    }

    @media screen and (max-width: 480px) {  
        width: 300px;
    }

    @media screen and (max-width: 320px) {
        width: 240px;
    }
}