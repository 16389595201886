.app__footer {
    background: var(--footer-gradient);
    padding: var(--app-padding);
    margin-top: 8rem;
    h1 {
        min-height: 3.5rem;
    }

    @media screen and (max-width: 900px) {
        padding: var(--app-padding-sm);
    }

}

.app__footer-content {
    padding-bottom: 5rem;
}

.app__footer-content_logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    padding: 2rem 0;
    img {
        width: 100%;
    }
    p {
        font-family: var(--font-alt);
        color: #fff;
        font-size: 1.5em;
        justify-content: center;
        align-items: center;
    }
}
        
.app__footer-content_info {
    display: grid;
    grid-template-columns: 25% 25% 20% 20%;
    grid-gap: 3rem;
    color: #fff;

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
        h1 {
            font-size: 36px;
        }
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
}

.app__footer-content_card {
    padding: .75rem;
    h4 {
        margin-bottom: .75rem;
        text-transform: capitalize;
    }
    
    a, p {
        color: #F9EFEF;
        font-weight: 400;
        font-size: 16px;
        line-height: 136.4%;
    }

    @media screen and (max-width: 1100px) {
        a, p {
            font-size: 14px;
        }
    }

    @media screen and (max-width: 900px) {
        h4 {
            font-size: 24px;
        }
        a {
            font-size: 22px;
        }
        p {
            font-size: 20px;
        }
    }
}

.app__footer-content_info-links {
    text-transform: capitalize;
}

.app__footer-content_info-contacts {
    h1 {
        text-transform: capitalize;
    }

    @media screen and (max-width: 1100px) {
        h1 {
            font-size: 28px;
        }
    }
}

.app__footer-content_info-contacts, 
.app__footer-content_info-location {
    @media screen and (max-width: 900px) {
        .app__footer-content_card, h1 {
            text-align: right;
        }
    }

    @media screen and (max-width: 600px) {
        .app__footer-content_card, h1 {
            text-align: left;
        }
    }
}

.app__footer-content_info-social,
.app__footer-content_info-location {
    @media screen and (max-width: 600px) {
        h1 {
            min-height: 0;
        }
    }
}